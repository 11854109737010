.desktop-modal-preview {
  .modal-dialog {
    max-width: 848px;
    .modal-content {
      height: 90vh;
      overflow: hidden;
      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .modal-title {
          color: #181c32;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
        .close-icon {
          cursor: pointer;
        }
      }
      .desktop-modal-body {
        overflow: hidden;
        height: 100%;
        // Preview css
        .preview-page {
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
          border-radius: 12.5px;
          height: 100%;
          overflow-y: scroll;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
          // width: 352px;
          .top-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 50px;
            .logo {
              display: flex;
              max-width: 37px;
              height: 20px;
              max-height: 20px;
              //   width: 37px;
              //   height: 8.7px;
              .logo-image {
                // width: 100%;
                // height: 100%;
              }
            }
            .menu-ctn {
              display: flex;
              gap: 17.5px;
              align-items: center;
              .menu-link {
                display: flex;
                align-items: center;
                gap: 5px;
                .menu-icon {
                  width: 12.5px;
                  height: 12.5px;
                }
                .title {
                  color: #969696;
                  font-size: 10px;
                  font-weight: 600;
                  line-height: 12.5px;
                }
              }
            }
            .right-nav {
              display: flex;
              align-items: center;
              gap: 12.5px;
              .right-nav-icon {
                width: 15px;
                height: 15px;
              }
              .profile-icon {
                width: 20px;
                height: 20px;
              }
            }
          }
          .home-banner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 17.5px 50px 29.5px;
            .title {
              color: #fff;
              font-size: 17.5px;
              font-weight: 600;
              line-height: 22.5px;
            }
            .tier-ctn {
              display: flex;
              gap: 7.5px;
              align-items: center;
              padding: 7.5px 10px;
              border-radius: 9px;
              background-color: #fff;
              .tier-img {
                width: 30px;
                height: 30px;
              }
              .tier-data {
                display: flex;
                flex-direction: column;
                gap: 2.2px;
                .tier-info {
                  display: flex;
                  align-items: center;
                  gap: 2.2px;
                  color: #1c1c1c;
                  font-size: 8.75px;
                  font-weight: 400;
                  .tier-name {
                    color: #1c1c1c;
                    font-size: 12.5px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 17.5px;
                  }
                }
                .tier-border {
                  border-radius: 25px;
                  background: #d9d9d9;
                  width: 100%;
                  height: 2.5px;
                  margin-top: 5px;
                  .tier-border-active {
                    border-radius: 25px;
                    width: 30%;
                    height: 100%;
                  }
                }
              }
              .right-chevron-img {
                width: 15px;
                height: 15px;
              }
            }
          }
          .body {
            border-radius: 12.5px 12.5px 0px 0px;
            background: #fff;
            padding: 30px 50px 0;
            margin-top: -12px;
            // Coupon
            .coupon-ctn {
              .coupon-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 15px;
                gap: 10px;
                .title {
                  color: #1c1c1c;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 20px;
                }
                .view-all {
                  color: #555150;
                  font-size: 8.75px;
                  font-weight: 400;
                  line-height: 11.25px;
                  display: flex;
                  align-items: center;
                  .right-icon {
                    width: 10px;
                    height: 10px;
                  }
                }
              }
              .coupons-wrapper {
                display: flex;
                align-items: center;
                gap: 20px;
                .coupon {
                  display: flex;
                  border-radius: 9px;
                  background: #fff;
                  gap: 10px;
                  .coupon-img-ctn {
                    min-width: 50px;
                    height: 50px;
                    overflow: hidden;
                    .coupon-img {
                      // width: 100%;
                      height: 100%;
                    }
                  }
                  .coupon-data {
                    display: flex;
                    flex-direction: column;
                    // width: 58px;
                    .title {
                      color: #1c1c1c;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 12.5px;
                    }
                    .store-points-ctn {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      .store-name {
                        color: #3f4254;
                        font-size: 8.75px;
                        font-weight: 400;
                        line-height: 10px;
                      }
                      .points-ctn {
                        display: flex;
                        align-items: center;
                        gap: 2.5px;
                        .coin-img-ctn {
                          display: flex;
                          width: 10px;
                          height: 10px;
                          .coin-img {
                            //   width: 100%;
                            height: 100%;
                          }
                        }
                        .points-text {
                          font-size: 10px;
                          font-weight: 700;
                          line-height: 12.5px;
                        }
                      }
                    }
                  }
                }
              }
            }
            // play and win
            .play-win-ctn {
              padding-top: 40px;
              .title {
                color: #1c1c1c;
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                padding-bottom: 15px;
              }
              .card-ctn {
                display: flex;
                align-items: center;
                gap: 20px;
                .card {
                  display: flex;
                  padding: 10px;
                  border-radius: 6.6px;
                  background-size: cover;
                  flex: 1;
                  height: 100px;
                  &:first-child {
                    background-image: url("../assets/spin-wheel-bg.svg");
                  }
                  &:nth-child(2) {
                    background-image: url("../assets/scratch-card-bg.svg");
                  }
                  &:last-child {
                    background-image: url("../assets/survey-bg.svg");
                  }
                  .title {
                    color: #f5f5f5;
                    font-size: 17.5px;
                    font-weight: 600;
                    line-height: 22.5px;
                    padding-bottom: 0;
                  }
                  .text {
                    color: #fff;
                    font-size: 8.75px;
                    font-weight: 400;
                    line-height: 11.25px;
                    padding: 7.5px 0 25px;
                  }
                  .cta-ctn {
                    display: flex;
                    align-items: center;
                    gap: 2.5px;
                    color: #fff;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 12.5px;
                    .right-chevron {
                      width: 10px;
                      height: 10px;
                    }
                  }
                }
              }
            }
            // Slider
            .slider-ctn {
              display: flex;
              align-items: center;
              gap: 20px;
              padding-top: 40px;
              .slider {
                flex: 1;
                // width: 96px;
                .slider-img {
                  width: 100%;
                }
              }
            }
            .dots-ctn {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              padding: 15px 0 40px;
              .dot {
                min-width: 5px;
                min-height: 5px;
                border-radius: 50%;
                background-color: #969696;
              }
            }
            // Feed
            .feed-ctn {
              display: flex;
              align-items: center;
              gap: 25px;
              padding-bottom: 62.5px;
              .feed {
                flex: 1;
                .feed-header {
                  display: flex;
                  gap: 3.3px;
                  justify-items: flex-start;
                  align-items: flex-start;
                  padding-bottom: 7.5px;
                  .profile-img-ctn {
                    display: flex;
                    width: 27.5px;
                    height: 27.5px;
                    .profile-img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .title-ctn {
                    flex-grow: 1;
                    .title {
                      color: #1c1c1c;
                      font-size: 12.5px;
                      font-weight: 600;
                      line-height: 17.5px;
                      padding-bottom: 2.5px;
                    }
                    .title-data {
                      display: flex;
                      flex: 1;
                      align-items: center;
                      gap: 2.5px;
                      color: #1c1c1c;
                      font-size: 8.75px;
                      font-weight: 400;
                      line-height: 11.25px;
                    }
                  }
                  .star-ctn {
                    display: flex;
                    width: 17.5px;
                    height: 17.5px;
                  }
                }
                .post-title {
                  color: #1c1c1c;
                  font-size: 12.5px;
                  font-weight: 600;
                  line-height: 17.5px;
                  padding-bottom: 7.5px;
                }
                .post-text {
                  color: #555150;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 12.5px;
                  padding-bottom: 10px;
                  .read-more {
                    color: #1bc5bd;
                    font-family: ProximaNova-Medium;
                    font-size: 8.75px;
                    font-weight: 400;
                    line-height: 12.5px;
                    text-decoration-line: underline;
                  }
                }
                .post-media-ctn {
                  // width: 96.8px;
                  // height: 96.8px;
                  .post-media {
                    width: 100%;
                    height: 100%;
                  }
                }
                .post-dots-ctn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 2.5px;
                  padding: 10px 0 7.5px;
                  .dot {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: #d9d9d9;
                    &:last-child {
                      width: 3.75px;
                      height: 3.75px;
                    }
                  }
                }
                .post-footer {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .like-ctn {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    .like-img-ctn {
                      display: flex;
                      width: 17.5px;
                      height: 17.5px;
                    }
                    .like-text {
                      color: #1c1c1c;
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 12.5px;
                    }
                  }
                  .post-share-ctn {
                    display: flex;
                    width: 17.5px;
                    height: 17.5px;
                    .post-share {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
          // Footer
          .footer-ctn {
            border-top: 0.556px solid #e0e0e0;
            background-color: #fafafa;
            padding: 40px 40px 12px;
            .footer-link-ctn {
              display: flex;
              justify-content: space-between;
              padding-bottom: 24px;
              border-bottom: 0.4px solid rgba(181, 181, 195, 0.5);
              .logo-ctn {
                max-width: 88px;
                .logo-img {
                  width: 100%;
                }
              }
              .link-ctn {
                display: flex;
                gap: 72px;
                .address-contact-ctn {
                  .address-ctn {
                    display: flex;
                    gap: 4.92px;
                    padding-bottom: 12px;
                    .pin-img-ctn {
                      display: flex;
                      width: 14.7px;
                      height: 14.7px;
                      .pin-img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                    .address-text {
                      color: #616473;
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 16px;
                      text-decoration-line: underline;
                      max-width: 140px;
                    }
                  }
                  .contact-ctn {
                    display: flex;
                    gap: 4.92px;
                    .phone-img-ctn {
                      display: flex;
                      width: 14.7px;
                      height: 14.7px;
                      .phone-img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                    .phone-text {
                      color: #616473;
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 16px;
                      text-decoration-line: underline;
                    }
                  }
                }
                .side-link-ctn {
                  display: flex;
                  gap: 60px;
                  .link-title {
                    color: #c8102e;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    padding-bottom: 18px;
                  }
                  .all-links {
                    display: flex;
                    flex-direction: column;
                    gap: 13.3px;
                    color: #616473;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 16px;
                  }
                  .follow-links-ctn {
                    display: flex;
                    gap: 8.8px;
                    .social-img-ctn {
                      display: flex;
                      width: 16px;
                      height: 16px;
                      .social-img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
            .copyright-ctn {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
              padding-top: 14.6px;
              .copy-img-ctn {
                display: flex;
                width: 12px;
                height: 12px;
                .copy-img {
                  width: 100%;
                  height: 100%;
                }
              }
              .copyright-text {
                color: #616473;
                font-size: 8px;
                font-weight: 500;
                line-height: 12px;
              }
            }
          }
        }
      }
    }
  }
}

// Preview Modal
body:has(.desktop-modal-preview-backdrop),
body:has(.mobile-modal-preview-backdrop) {
  overflow: hidden !important;
}

.modal-backdrop {
  &.fade {
    &.desktop-modal-preview-backdrop,
    &.mobile-modal-preview-backdrop {
      opacity: 0.6;
      background-color: #1c1c1c;
    }
  }
}

.mobile-modal-preview {
  .modal-content {
    height: 90vh;
    .close-icon {
      cursor: pointer;
    }
    .desktop-modal-body {
      height: 100%;
      overflow: hidden;
      .preview-page {
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
