.branding {
  .card-header {
    .card-label {
      color: #181c32;
    }
  }
  .branding-ctn {
    display: flex;
    gap: 24px;
    position: relative;

    .branding-form {
      flex: 1;

      .form-wrapper {
        border-radius: 8px;
        border: 1px solid #e4e6ef;
        &:not(:last-child) {
          margin-bottom: 24px;
        }

        .form-title {
          font-size: 16px;
          font-weight: 500;
          padding: 16px 24px;
          border-bottom: 1px solid #e4e6ef;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #181c32;
          .reset {
            cursor: pointer;
          }
        }
        .children-ctn {
          padding: 20px 24px;

          .tab-title-ctn {
            display: flex;
            gap: 28px;
            position: relative;
            .bottom-border {
              position: absolute;
              height: 2px;
              width: 100%;
              bottom: 0;
              background-color: #e4e6ef;
            }
            .tab-title {
              color: #b5b5c3;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px;
              padding-bottom: 12px;
              cursor: pointer;
              &.active {
                border-bottom: 2px solid #1bc5bd;
                color: #1bc5bd;
                position: relative;
                z-index: 1;
              }
            }
          }
          .tab-content {
            padding-top: 20px;
            // company logo
            .company-logo-ctn {
              .company-logo-input {
                display: flex;
                gap: 16px;
                .preview-ctn {
                  height: 100px;
                  label.company-logo {
                    .add-img {
                      width: 100px;
                      height: 100px;
                      border-radius: 8px;
                      border: 1px solid #e4e6ef;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    }
                  }
                  .preview {
                    width: 100px;
                    height: 100px;
                    border-radius: 8px;
                    overflow: hidden;
                    position: relative;
                    border: 1px solid #e4e6ef;
                    display: flex;
                    align-items: center;
                    .preview-img {
                      width: 100%;
                      // height: 100%;
                    }
                    &:hover {
                      .edit-image {
                        display: flex;
                        label {
                          cursor: pointer;
                        }
                      }
                    }
                    .edit-image {
                      display: none;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background-color: rgba(0, 0, 0, 0.3);
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    }
                  }
                }
                .company-logo-info {
                  display: flex;
                  align-items: center;
                  li {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 16px;
                    &:not(:last-child) {
                      padding-bottom: 12px;
                    }
                  }
                }
              }
              .image-error {
                color: #f64e60;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                display: flex;
                gap: 4px;
                align-items: center;
                padding-top: 12px;
              }
            }
            &.banner-content {
              position: relative;
              .default-fill {
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                color: #616473;
                .fill-color {
                  width: 12px;
                  height: 12px;
                  border-radius: 1px;
                }
              }
            }
          }
          .form-subtitle-text {
            color: #181c32;
          }
          // Color Field
          .color-field-ctn {
            .color-field {
              display: flex;
              align-items: center;
              gap: 12px;
              background-color: #f3f6f9;
              border: 1px solid #e4e6ef;
              border-radius: 8px;
              padding: 8px;
              margin-top: 16px;
              position: relative;
              cursor: pointer;
              .preview-color {
                width: 40px;
                height: 40px;
                border-radius: 4px;
              }
              .hex-code {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                flex-grow: 1;
              }
              .input-color {
                visibility: hidden;
                width: 0;
                position: absolute;
                left: 0;
                bottom: 0;
              }
            }
          }
          // Font family
          .font-family-ctn {
            .font-family-select {
              margin-top: 16px;
              & > div {
                background-color: #f3f6f9;
                border-radius: 8px;
                padding: 4px 6px;
                border: 1px solid #e4e6ef;
              }
            }
          }
          // Round container
          .round-field-ctn {
            .radio-ctn {
              display: flex;
              gap: 16px;
              padding-top: 16px;
              .radio-block {
                flex: 1;
                border-radius: 8px;
                border: 1px solid #e4e6ef;
                .container-shape {
                  overflow: hidden;
                  height: 60px;
                  padding: 24px 12px 0;
                  .container {
                    width: 100%;
                    border: 2px solid #1bc5bd;
                    height: 100px;
                    background-color: #fff;
                    &.round {
                      border-radius: 24px;
                    }
                  }
                }
                .button-shape {
                  padding: 16px 12px;
                  border-top: 1px solid #e4e6ef;
                  border-bottom: 1px solid #e4e6ef;
                  .btn {
                    background-color: #1bc5bd;
                    height: 28px;
                    width: 100%;
                    border-radius: 0;
                    &.round {
                      border-radius: 24px;
                    }
                  }
                }
                .radio-input {
                  padding: 8px 12px;
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  label {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    // Preview
  }
}
