/* Tabs section */
.interMenu {
  // margin: -25px -25px 0px -25px;
  &.interMenuGraph {
    position: absolute;
    z-index: 1;
  }
}
.interMenuList {
  background: #fff;
  border-radius: 0.42rem;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
  padding: 0 2.25rem;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #e4e6ef;

  &.interMenuGraphList {
    box-shadow: none;
    border: none;
    padding: 0 25px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #616473;
  }
  & li.menuItem {
    display: inline-block;
    & a {
      padding: 0.85rem 0;
      display: block;
      color: map-deep-get($pageTabs, "dActive", "link");
      text-decoration: none;
      // font-weight: 500;
      margin: 0 1rem;
    }
    &:first-child {
      a {
        // margin-left: 0;
      }
    }
    &:hover,
    &.activeTab {
      // border-color: map-deep-get($pageTabs, 'active', 'border');
      & a,
      & a:hover {
        color: map-deep-get($pageTabs, "active", "link");
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: map-deep-get($pageTabs, "active", "border");
      }
    }

    &.menuItemGraph {
      vertical-align: bottom;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &:hover,
      &.activeTabGraph {
        & a,
        & a:hover {
          color: map-deep-get($pageTabs, "active", "link");
          text-decoration: none;
          border-top: 2px solid;
          border-bottom: none;
          border-color: map-deep-get($pageTabs, "active", "border");
        }
      }
    }
  }
}
