/* Input Section */

.requiredField {
  color: $error;
}
.ufyndLabel {
  color: $priLableClr
}
.ufyndTextBox {
  border-color: rgba(0,0,0,.2);
  color: $priFontClr;
  background-color: $primaryBGClr;
  &:focus {
    border-color: rgba(0,0, 0, .05);
    background-color: rgba($primaryBGClr, .5);
  }
  &:disabled {
    opacity: .5;
  }
}
.ufyndTextBox_search{
  width: 280px;
  margin: 0 15px;
}

.ufyndSearchAppend{
  height:calc(1.5em + 1.3rem + 2px);
  color:$priFontClr;
}

.ufyndSearchIcon{
  border-color: map-get($btnRegular , 'bg' );
  color: map-get($btnRegular , 'color' );
  background-color: map-get($btnRegular , 'bg' );
  &:hover,&:focus {
    border-color: map-get($btnRegular , 'bg' );
    color: map-get($btnRegular , 'color' );
    background-color: rgba(map-get($btnRegular , 'bg' ), .8);
  }
  & i {
    border-color: map-get($btnRegular , 'bg' );
    color: map-get($btnRegular , 'color' );
    background-color: map-get($btnRegular , 'bg' );
    &:hover,&:focus {
      border-color: map-get($btnRegular , 'bg' );
      color: map-get($btnRegular , 'color' );
      background-color: rgba(map-get($btnRegular , 'bg' ), .8);
    }
  }
}

.dateSearchField{
  border-color: rgba(0, 0, 0, 0.2);
  color: #373a3c;
  background-color: #f2f3f7;
  width: 240px;
  margin: 0 15px;
  & .MuiOutlinedInput-input{
    padding: 12px 14px;
  }
  & .MuiOutlinedInput-root{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.searchDateInput {
    background-color: #fff;
    border-color: #E4E6EF;
    .MuiOutlinedInput-root {
      fieldset {
        border-color: #E4E6EF !important;
        border-width: 1px;
      }
    }
    input {
      padding: 0.9rem 1rem 0.86rem;
    }
  }
}



.image_hover{
  margin:0 15px 0 15px;
  &:hover{
    -ms-transform: scale(4.0);
    -webkit-transform: scale(4.0);
    transform: scale(4.0);
    background: white;
  }
}

.video-preview{
  margin:0 15px 0 15px;
}


/* Buttons */
.pgBckBtn {
  color: map-get($pageBckBtn, 'color') !important;
  background-color: map-get($pageBckBtn, 'bg') !important;
  border-color: map-get($pageBckBtn, 'bg') !important;
  &:hover,&:active,&:focus {
    color: map-get($pageBckBtn, 'color') !important;
    background-color: map-get($pageBckBtn, 'bg') !important;
    border-color: map-get($pageBckBtn, 'bg') !important;
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
}
.ufyndIconBtn {
  border:none;
  padding: 5px;
  border-radius: 50%;
  font-size: calc-rem(12);
  &:hover {
    background-color: transparent;
  }
}
.ufyndIconBtn_primary {
  color: $primaryClr;
  &:hover {
    color: $primaryClr;
    background-color: rgba($primaryClr, .1);
  }
}

.ufyndBtn {
  &:disabled, .disabled {
    border: map-get($btnDisbale_pri , 'bg' );
    color: map-get($btnDisbale_pri , 'color' );
    background-color: map-get($btnDisbale_pri , 'bg' );
  }
}

.btn.ufyndBtn_primary {
  border-color: map-get($btnRegular , 'bg' );
  color: map-get($btnRegular , 'color' );
  background-color: map-get($btnRegular , 'bg' );
  &.disabled {
    background-color: #c1c1c1 !important;
    border-color: #c1c1c1 !important;
    cursor: not-allowed;
  }
  &:hover,&:focus {
    border-color: map-get($btnRegular , 'bg' );
    color: map-get($btnRegular , 'color' );
    background-color: rgba(map-get($btnRegular , 'bg' ), .8);
  }
}

.ufyndBtn_danger {
  border-color: map-get($btnDanger , 'bg' );
  color: map-get($btnDanger , 'color' );
  background-color: map-get($btnDanger , 'bg' );
  &:hover,&:focus{
    border-color: map-get($btnDanger , 'bg' );
    color: map-get($btnDanger , 'color' );
    background-color: map-get($btnDanger , 'bg' );
  }
}
.ufyndBtn_link {
  border-color: #fff;
  color: #797a7c;
  background-color: #fff;
  &:hover,&:focus{
    border-color: #fff;
    color: #797a7c;
    background-color: #fff;
  }
}

.is-error {
  border-color: #F64E60 !important;
}
.is_errorElem {
  border: 1px solid ;
  border-color: #F64E60 !important;
  border-radius: 4px;
}

.searchWrapper {
  position: relative;

  & .clearButton {
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(27, 27, 27,.3);
    border:none;
    color: #fff;
    padding: 0;
    right: 25px;
    top: 10px;
  }
}
