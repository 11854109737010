.preview-page {
  &.preview-page-mobile {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    width: 248px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    .mobile-header {
      .top-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 56px 22px 18px;
        .logo {
          display: flex;
          // max-width: 37px;
          max-height: 24px;
          // width: 37px;
          height: 24px;
          .logo-image {
            // width: 100%;
            height: 100%;
          }
        }
        .menu-ctn {
          display: none;
          gap: 7.7px;
          align-items: center;
          .menu-link {
            display: flex;
            align-items: center;
            gap: 2.2px;
            .title {
              color: #969696;
              font-size: 4.4px;
              font-weight: 600;
              line-height: 5.5px;
            }
          }
        }
        .right-nav {
          display: flex;
          align-items: center;
          gap: 15.5px;
          .right-nav-icon {
            width: 18px;
            height: 18px;
          }
          .profile-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
      .home-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7.7px 22px 17.7px;
        .title {
          display: none;
          color: #fff;
          font-size: 7.7px;
          font-weight: 600;
          line-height: 9.9px;
        }
        .tier-ctn {
          display: flex;
          gap: 3.3px;
          align-items: center;
          padding: 9.3px;
          margin-bottom: 15px;
          background-color: #fff;
          .tier-img {
            width: 31px;
            height: 31px;
          }
          .tier-data {
            display: flex;
            flex-direction: column;
            gap: 9.3px;
            .tier-info {
              display: flex;
              align-items: center;
              gap: 6.2px;
              color: #1c1c1c;
              font-size: 9.3px;
              font-weight: 400;
              .tier-name {
                color: #1c1c1c;
                font-size: 10.85px;
                font-style: normal;
                font-weight: 600;
                line-height: 15.5px;
              }
            }
            .tier-border {
              border-radius: 77.5px;
              background: #d9d9d9;
              width: 100%;
              height: 3.1px;
              .tier-border-active {
                border-radius: 77.5px;
                width: 30%;
                height: 100%;
              }
            }
          }
          .right-chevron-img {
            width: 18.6px;
            height: 18.6px;
            margin-left: 3px;
          }
        }
      }
    }
    .body {
      border-radius: 15.5px 15.5px 0px 0px;
      background: #fff;
      padding: 15.5px 0;
      margin-top: -10px;
      // Coupon
      .coupon-ctn {
        padding: 0 15.5px 27.9px;
        .coupon-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 15.5px;
          .title {
            color: #1c1c1c;
            font-size: 15.5px;
            font-weight: 600;
            line-height: 18.6px;
          }
          .view-all {
            display: none;
            color: #555150;
            font-size: 3.85px;
            font-weight: 400;
            line-height: 4.95px;
            // display: flex;
            align-items: center;
          }
        }
        .coupons-wrapper {
          display: flex;
          flex-direction: column;
          //   align-items: center;
          gap: 15.5px;
          .coupon {
            display: flex;
            gap: 10.36px;
            background: #fff;
            .coupon-img-ctn {
              width: 46px;
              height: 46px;
              .coupon-img {
                // width: 100%;
                height: 100%;
              }
            }
            .coupon-data {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .title {
                color: #1c1c1c;
                font-size: 9px;
                font-style: normal;
                font-weight: 600;
                line-height: 10.3px;
              }
              .store-points-ctn {
                .store-name {
                  color: #555150;
                  font-size: 9px;
                  font-weight: 400;
                  line-height: 12.9px;
                }
                .points-ctn {
                  display: flex;
                  align-items: center;
                  gap: 2px;
                  .coin-img-ctn {
                    display: flex;
                    width: 9px;
                    height: 9px;
                    .coin-img {
                      //   width: 100%;
                      height: 100%;
                    }
                  }
                  .points-text {
                    overflow: hidden;
                    font-size: 9px;
                    font-weight: 700;
                    line-height: 10.3px;
                  }
                }
              }
            }
          }
        }
        .explore-coupons {
          font-size: 10.85px;
          line-height: 15.5px;
          display: flex;
          gap: 3.1px;
          align-items: center;
          justify-content: center;
          padding-top: 15.5px;
          font-weight: 600;
          .right-chevron {
            width: 12.4px;
            height: 12.4px;
          }
        }
      }
      // play and win
      .play-win-ctn {
        padding: 0 0 27.9px 15.5px;
        .title {
          color: #1c1c1c;
          font-size: 15.6px;
          font-weight: 600;
          line-height: 18.6px;
          padding-bottom: 18.6px;
        }
        .card-ctn {
          display: flex;
          align-items: center;
          gap: 8.8px;
          overflow: hidden;
          .card {
            display: flex;
            padding: 9.3px;
            background-size: cover;
            flex: 1;
            min-width: 124px;
            height: 124px;
            &:first-child {
              background-image: url("../assets/mobile-spin-wheel.svg");
            }
            &:nth-child(2) {
              background-image: url("../assets/mobile-scratch-card.svg");
            }
            &:last-child {
              background-image: url("../assets/mobile-survey.svg");
            }
            .title {
              color: #f5f5f5;
              font-size: 18.6px;
              font-weight: 600;
              line-height: 21.7px;
              padding-bottom: 0;
            }
            .text {
              color: #fff;
              font-size: 9.3px;
              font-weight: 400;
              line-height: 12.4px;
              padding: 3.1px 0 30px;
            }
            .cta-ctn {
              display: flex;
              align-items: center;
              gap: 3.1px;
              color: #fff;
              font-size: 10.85px;
              font-weight: 600;
              line-height: 15.5px;
              .right-icon {
                width: 12.4px;
                height: 12.4px;
              }
            }
          }
        }
      }
      // Slider
      .slider-ctn {
        display: flex;
        align-items: center;
        gap: 8.8px;
        padding: 0 15.5px;
        overflow: hidden;
        .slider {
          flex: 1;
          min-width: 201.5px;
          .slider-img {
            width: 100%;
          }
        }
      }
      .dots-ctn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.2px;
        padding: 9.3px 0 27.9px;
        .dot {
          min-width: 4.65px;
          min-height: 4.65px;
          border-radius: 50%;
          background-color: #969696;
        }
      }
      // Feed
      .feed-ctn {
        display: flex;
        flex-direction: column;
        gap: 11px;
        padding: 0 15.5px 27.5px;
        .feed-title {
          color: #1c1c1c;
          font-size: 15.5px;
          font-weight: 600;
          line-height: 18.6px;
          display: flex;
          align-self: flex-start;
          // padding-left: 15.5px;
        }
        .feed {
          flex: 1;
          .feed-header {
            display: flex;
            gap: 9.3px;
            justify-items: flex-start;
            align-items: flex-start;
            .profile-img-ctn {
              display: flex;
              width: 34px;
              height: 34px;
              .profile-img {
                width: 100%;
                height: 100%;
              }
            }
            .title-ctn {
              flex-grow: 1;
              .title {
                color: #1c1c1c;
                font-size: 12.4px;
                font-weight: 600;
                line-height: 15.5px;
                padding-bottom: 3.1px;
              }
              .title-data {
                display: flex;
                flex: 1;
                align-items: center;
                gap: 3.1px;
                color: #1c1c1c;
                font-size: 9.3px;
                font-weight: 400;
                line-height: 12.4px;
              }
            }
            .star-ctn {
              display: flex;
              width: 21.7px;
              height: 21.7px;
            }
          }
          .post-title {
            color: #1c1c1c;
            font-size: 12.4px;
            font-weight: 600;
            line-height: 15.5px;
            padding: 9.3px 0;
          }
          .post-text {
            color: #555150;
            font-size: 10.85px;
            font-weight: 400;
            line-height: 15.5px;
            padding-bottom: 9.3px;
            .read-more {
              color: #1bc5bd;
              font-size: 9.3px;
              font-weight: 400;
              line-height: 12.4px;
              text-decoration-line: underline;
            }
          }
          .post-media-ctn {
            // width: 96.8px;
            // height: 96.8px;
            .post-media {
              width: 100%;
              height: 100%;
            }
          }
          .post-dots-ctn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.1px;
            padding: 12.4px 0 9.3px;
            .dot {
              width: 6.2px;
              height: 6.2px;
              border-radius: 50%;
              background-color: #d9d9d9;
            }
          }
          .post-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .like-ctn {
              display: flex;
              gap: 6.2px;
              align-items: center;
              .like-img-ctn {
                display: flex;
                width: 21.7px;
                height: 21.7px;
              }
              .like-text {
                color: #1c1c1c;
                font-size: 10.85px;
                font-weight: 400;
                line-height: 15.5px;
              }
            }
            .post-share-ctn {
              display: flex;
              width: 21.7px;
              height: 21.7px;
              .post-share {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .explore-feed {
          font-size: 10.85px;
          line-height: 15.5px;
          display: flex;
          gap: 3.1px;
          align-items: center;
          justify-content: center;
          padding-top: 4.5px;
          font-weight: 600;
          .right-chevron {
            width: 12.4px;
            height: 12.4px;
          }
        }
      }
    }
    // Footer
    .mobile-footer-ctn {
      box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 10px 10px 0 0;
      .mobile-footer-menu {
        display: flex;
        align-items: center;
        .normal-nav {
          display: flex;
          flex-grow: 1;
          padding: 10.85px 0;
          .menu-link {
            min-width: 44.95px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3.1px;
            .menu-home-icon {
              width: 18.6px;
              height: 18.6px;
            }
            .menu-icon {
              width: 18.6px;
              height: 18.6px;
            }
            .menu-text {
              font-size: 7.75px;
              line-height: 10.85px;
            }
          }
        }
        .cta-nav {
          display: flex;
          flex-direction: column;
          width: 62px;
          align-items: center;
          padding: 10.85px 0;
          gap: 3.1px;
          .scan-img-wrapper {
            width: 18px;
            height: 18px;
            position: relative;
            .scan-img-ctn {
              width: 49.6px;
              height: 49.6px;
              border-radius: 50%;
              padding: 13.5px;
              position: absolute;
              left: -80%;
              top: -206%;
              background-color: #fff;
              box-shadow: 0 5px rgba(0, 0, 0, 0.1);
              .mobile-scan-earn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .menu-text {
            font-size: 7.75px;
            line-height: 10.85px;
          }
        }
      }
    }
  }
}
