/* Section */
.sectionBredcrum {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.crumbWrapper {
  font-size: calc-rem(11);
  // margin-left: 30px;
  & .sectionBCTitle {
    color: $primaryClr;
    font-size: calc-rem(14);
  }
  & .crumbBackBtn {
    cursor: pointer;
  }
}
.pgBckBtn + .crumbWrapper {
  margin-left: 30px;
}


.ufyndPageWrapper {
  padding-top: 30px;
}
.ufyndWrapper {
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
}

.ufyndTableWrapper {
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  border-radius: 4px;
  padding: 25px 0 0;
}
.ufyndTableHeader {
  padding: 0 25px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h3{
    font-size: calc-rem(14);
    margin-bottom: 0;
    // flex:1
  }

  & .toolBar {
    display: flex;
    flex: 1;
    // justify-content: space-between;
    // justify-content: flex-end;
    & a, button.ufyndBtn{
      margin-left: auto;
    }
  }
}

.ufyndFormWrapper_half {
  width: 55%;
}


.ufyndLoader {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & .spinner {
    margin-left: 0;
  }
}

.ufyndEmpty {
  width: 100%;
  height: 65vh;
  background: #fff;
  border: 2px dashed $primaryClr;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h6, & h4 {
    margin-bottom: 15px;
  }

  & img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
  }
}

/* Demo3 UI */
// .sectionTitle {
//   color: #6993ff;
//   margin-bottom: 20px;
// }
.bookingChips {
  margin: 0 6px 13px !important;
  min-width: 130px;
}
.chipSection {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 13px;
}

.dashboard{
  margin: 10px 10px;
}


.font-center{
  text-align: center;
}

.dashboard-analytics{
  padding-bottom: 20px;
  .font-center{
    text-align: center;
  }
  .dataCardDiv{
    display: flex;
    justify-content: space-evenly;
    .card-custom{
      display: flex;
    }
  }
  .card-spacer{
    padding: 0px !important;
  }
}