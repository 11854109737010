$text__size--default: 13 !default;
$primaryBGClr: #f2f3f7;
$primaryClr: #1BC5BD;
$priTabLinkClr: #b3b3b3;
$disableClr: $priTabLinkClr;
$disableBtnClr: #c1c1c1;
$priFontClr: #373a3c;
$secFontClr: #797a7c;
$priLableClr: #797a7c;
$error: #d5242c;
$counter: #6993FF;
$cancelClr: #f64e60;
$secondaryGrey: #b5b5c3;

$pageTabs: (
  active: (
    border: $primaryClr,
    link: $primaryClr
  ),
  dActive: (
    border: #fff,
    link: $priTabLinkClr
  )
);
$pageBckBtn: (
  color: $primaryClr,
  bg: #fff
);
$btnDisbale_pri: (
  color: #fff,
  bg: $disableBtnClr
);
$btnRegular: (
  color: #fff,
  bg: $primaryClr
);
$btnDanger: (
  color: #fff,
  bg: $primaryClr
);

$successClr: (
  bold: #224421,
  light: #dff0d8
);
$warningClr: (
  bold: #7b6b32,
  light: #fae497
);
$errorClr: (
  bold: #a94442,
  light: #f2dede
);
