// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

// .nav-link:focus {
//     outline: none;
// }

.form-wrapper .required .col-form-label:after {
  content: " *";
  color: #f64e60;
}

.form-wrapper .required .form-label:after {
  content: " *";
  color: #f64e60;
}

.heading-wrapper {
  min-height: 40px;
  text-transform: uppercase;
}

.dashboard-heading-wrapper {
  min-width: 40%;
}

.rdt_TableCol > div {
  cursor: default;
}

.rdt_TableCol > div:has(span) {
  cursor: pointer;
}

.dashboard-heading-wrapper {
  .dashboard-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: #181c32;

    &-desc {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.24;
      color: #616473;
    }
  }

  .last-updated {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.24;
    color: #b5b5c3;
    margin-top: 8px;
    display: inline-block;
  }
}

.card-label-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #181c32;
  margin-bottom: 0;
}

.highchart-content {
  padding-left: 0;
  padding-right: 0;
}

.chart {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 27px;
    right: 23px;
    width: 67px;
    height: 10px;
    background: white;
    z-index: 1;
  }
}

.card-detail-stats {
  flex-direction: row !important;

  .card-left {
    width: 64%;
  }

  .card-right {
    flex-grow: 1;
    border-left: 1px solid #e9e9e9;
    background-color: #fafafa;
    padding: 24px;
  }
}

.chart-header {
  min-height: auto !important;
  padding: 24px 24px 0 !important;
}

.chart-body {
  padding: 24px !important;
}

.chart-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start !important;

  .chart-facts-figures {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;

    .chart-figures {
      font-size: 16px;
      font-weight: 500;
      color: #000;

      &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 11px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .chart-label {
      font-size: 12px;
      font-weight: 500;
      color: #616473;
    }
  }
}

.stats-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.stats-list-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 0;

  &:first-of-type {
    padding-top: 16px;
  }
}

.stats-list-heading {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #616473;

  svg {
    margin-right: 10px;
  }
}

.stats-list-desc {
  font-size: 24px;
  font-weight: normal;
  color: #181c32;
  display: flex;
  align-items: center;
}

.percent {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  padding: 4px 8px;
  border-radius: 5px;
  margin-left: 12px;

  &.green {
    color: #32bd72;
    background-color: rgba(50, 189, 114, 0.1);
  }

  &.red {
    color: #bd3232;
    background-color: rgba(189, 50, 50, 0.1);
  }

  &.secondary {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.store {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &-list {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #e9e9e9;
  }
  &-count {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    max-width: 12px;
  }
  &-logo {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: black;
    border-radius: 4px;
    margin: 0 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &-detail {
    display: flex;
    flex-direction: column;
  }
  &-name {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  &-scans {
    font-size: 12px;
    font-weight: 500;
    color: #616473;
  }
}
.see-more {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  color: #1bc5bd;
  padding: 18px 0;
}

.row-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  .box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
  }

  & .one-third-box {
    width: calc(100% / 3 - 20px);
    margin-top: 20px;

    .box-body {
      width: 100%;
    }
  }
}

.loyalty-box-heading {
  font-size: 14px;
  font-weight: 500;
  color: #616473;
  min-height: 42px;
}

.loyalty-box-number {
  font-size: 32px;
  font-weight: normal;
  color: #181c32;
  margin-top: 16px;
}

.overall-stats .loyalty-box-number {
  font-size: 26px;
}

.loyalty-box-number-2 {
  font-size: 36px;
  font-weight: normal;
  color: #181c32;
  display: flex;
  align-items: center;
}

.date-picker-column {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    height: 50px;
  }
  .react-daterange-picker__wrapper {
    border: none;
    background-color: white;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus,
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive {
    background-color: #17c191;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #17c191 !important;
    color: white;
  }

  .react-calendar__tile--active:first-child {
    background-color: #17c191 !important;
    color: white;
  }

  .react-calendar__tile--active:last-child {
    background-color: #17c191 !important;
    color: white;
  }

  .react-calendar__tile--active {
    background: rgba(23, 193, 145, 0.7) !important;
    background: white;
  }
}

.date-picker-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  height: fit-content;

  i {
    font-size: 10px;
    margin: 0 8px;
  }
}

.last-day-count {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  color: #181c32;
  background-color: #f3f6f9;
  padding: 4px 8px;
  border-radius: 5px;
  margin-right: 8px;
  min-width: 110px;
  text-align: center;
}

.date-selector {
  svg {
    margin-right: 8px;
  }

  .date {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    color: #181c32;
  }
}

.loyalty-ratio {
  font-size: 12px;
  font-weight: 500;
  color: #616473;
}
