#dashboard {
  width: 100%;
  height: 80vh;

  iframe {
    width: 100%;
    height: 80vh;
    border: 0;
  }
}
